<template>
    <div class="auth-content">
        <router-view />
    </div>
</template>

<script>

export default {
    data() {
        return {
            snackbar: {
                show: false,
                message: null,
                color: ''
            }
        }
    }
}
</script>
